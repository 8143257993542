var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user.party.quest.completed ? _c('b-modal', {
    attrs: {
      "id": "quest-completed",
      "title": _vm.title,
      "size": "md",
      "hide-footer": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true
    },
    on: {
      "hide": _vm.hide
    }
  }, [_c('div', {
    staticClass: "modal-body text-center"
  }, [_c('div', {
    staticClass: "quest",
    class: `quest_${_vm.user.party.quest.completed}`
  }), _vm.questData.completion && typeof _vm.questData.completion === 'function' ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.questData.completion())
    }
  }) : _vm._e(), _c('div', {
    staticClass: "text-center"
  }, [_vm._m(0), _c('quest-rewards', {
    attrs: {
      "quest": _vm.questData
    }
  })], 1)]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.setQuestCompleted();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ok')) + " ")])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v(" " + _vm._s(_vm.$t('paymentYouReceived')) + " ")]);

}]

export { render, staticRenderFns }